import React from 'react'
import HeaderNav from '../nav'
import { Link } from 'gatsby'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

const Header = ({ siteTitle }) => (
  <div className="nav-wrapper">
    <div className="top-nav primary-bg-color">
      <div className="container-fluid px-md-5">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-xl-4 col-2xl-6 tagline">
            <p className="text-white top-headline">
              "Together... Helping You Find Your Perfect Dream Home in the
              Rockies!"
            </p>
          </div>
          <div className="col-12 col-xl-8 col-2xl-6 ctas">
            <div className="d-flex justify-content-end">
              <div className="d-relative top-button  mr-4">
                <a className="top-cta" />
                <div className="d-flex align-items-center d-relative">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#ffffff"
                      version="1.1"
                      id="Capa_1"
                      height={20}
                      width={20}
                      viewBox="0 0 395.71 395.71"
                    >
                      <g>
                        <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738   c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388   C335.179,61.609,273.569,0,197.849,0z M197.849,88.138c27.13,0,49.191,22.062,49.191,49.191c0,27.115-22.062,49.191-49.191,49.191   c-27.114,0-49.191-22.076-49.191-49.191C148.658,110.2,170.734,88.138,197.849,88.138z" />
                      </g>
                    </svg>
                  </div>
                  <div className="button-info ml-2">
                    <span className="top-heading">Address</span>
                    {/* <p className="top-label">
                      12265 Oracle Boulevard, Suite 200 - Colorado Springs, CO
                      80921
                    </p> */}
                    <p className="top-label ">
                      12265 Oracle Boulevard, Suite 200
                      <br />
                      Colorado Springs, CO 80921
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-relative top-button secondary-button d-flex justify-content-center align-items-center">
                <a href="mailto:brianboals@gmail.com" className="top-cta" />
                <div className="d-flex align-items-center d-relative">
                  <div className="icon">
                    <svg
                      viewBox="0 -2.5 20 20"
                      version="1.1"
                      fill="#000000"
                      height={20}
                      width={20}
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-340.000000, -922.000000)"
                            fill="#fff"
                          >
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              <path
                                d="M294,774.474 L284,765.649 L284,777 L304,777 L304,765.649 L294,774.474 Z M294.001,771.812 L284,762.981 L284,762 L304,762 L304,762.981 L294.001,771.812 Z"
                                id="email-[#fffff]"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="button-info ml-2 ">
                    <span className="top-heading">Email</span>
                    <p className="top-label">brianboals@gmail.com</p>
                  </div>
                </div>
              </div>
              <div className="d-relative top-button secondary-button ml-4 blinking-button d-flex justify-content-center align-items-center">
                <div className="d-flex align-items-center d-relative">
                  <a href="tel:7194599955" className="top-cta" />
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      width={25}
                      height={25}
                      viewBox="0 0 24 24"
                      stroke="#fff"
                      className="mt-1"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
                      </g>
                    </svg>
                  </div>
                  <div className="button-info ml-2">
                    <span className="top-heading">Text or Call</span>
                    <p className="top-label">(719) 459-9955</p>
                  </div>
                </div>
              </div>
              <div className="d-relative top-button button-white ml-4 dream-home d-flex justify-content-center align-items-center">
                <div className="d-flex align-items-center d-relative">
                  <Link to="/property-listings/" className="top-cta" />
                  <div className="icon">
                    <svg
                      width={26}
                      height={26}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0" />
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        {' '}
                        <path
                          d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
                          stroke="#013DA5"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="ml-2">
                    <p className="top-label">Find Dream Home</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="menu-container">
      <div className="container-fluid px-md-5">
        <div className="row d-flex align-items-center flex-nowrap justify-content-between">
          <div className="col-9 col-sm-3 col-md-3">
            <div className="nav-brand d-flex justify-content-start align-items-center">
              <Link to="/">
                <img
                  className="img-fluid"
                  src="/img/remax-logo-black.png"
                  alt="logo"
                />
              </Link>
            </div>
          </div>
          <div className="col-9 col-sm-2 col-md-9 d-xl-inline d-none">
            <div className="nav-menu d-flex justify-content-end align-items-center">
              <ul className="list-unstyled d-flex main-nav mb-0">
                <li>
                  <Link to="/">Home </Link>
                </li>
                <li>
                  <Link to="/testimonials/">Testimonials</Link>
                </li>
                <li>
                  <Link to="/physicians-medical/">Physician</Link>
                </li>
                <li>
                  <Link to="/military/">Military</Link>
                </li>
                <li>
                  <Link to="/buying-process/">First-Time Homebuyers</Link>
                </li>
                <li className="dropdown-btn">
                  <DropdownButton
                    id="dropdown-item-button"
                    title="Take Action Now"
                  >
                    <Dropdown.Item href="/property-listings/">
                      Listings
                    </Dropdown.Item>
                    <Dropdown.Item href="/buy/">Find a Home</Dropdown.Item>
                    <Dropdown.Item href="/selling-process/">Sell</Dropdown.Item>
                    <Dropdown.Item href="/finance/">Finance</Dropdown.Item>
                  </DropdownButton>
                </li>
                <li className="dropdown-btn">
                  <DropdownButton id="dropdown-item-button" title="Resources">
                    <Dropdown.Item href="/about-colorado-springs/">
                      About Colorado Springs
                    </Dropdown.Item>
                    <Dropdown.Item href="/">Neighborhoods</Dropdown.Item>
                    <Dropdown.Item href="/the-buffini-difference/">
                      The Buffini Difference
                    </Dropdown.Item>
                    <Dropdown.Item href="https://www.buffini.com/products/bold-predictions/bold-predictions-2025-watch.aspx">
                      Bold Predictions 2025
                    </Dropdown.Item>
                    <Dropdown.Item href="/news/">Recent Articles</Dropdown.Item>
                  </DropdownButton>
                </li>
                <li className="dropdown-btn">
                  <DropdownButton
                    id="dropdown-item-button"
                    title="Brian Boals Team"
                  >
                    {/* <Dropdown.Item href="/preferred-vendors/">
                      Preferred Vendors
                    </Dropdown.Item> */}
                    <Dropdown.Item href="/about/">About Us</Dropdown.Item>
                    <Dropdown.Item href="/fall-roundup/">
                      Fall Round Up
                    </Dropdown.Item>
                    <Dropdown.Item href="/contact/">Contact</Dropdown.Item>
                  </DropdownButton>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-3 col-sm-auto col-xl-5 d-flex justify-content-end align-items-center text-center">
            <div className="inline-block d-xl-none top-links">
              {/* <ul className="list-unstyled d-flex justify-content-end my-0 ml-0 top-info">
                <li className="pr-3">
                  <a href="tel:719-4599-955">
                    <img
                      src="/img/003-mobile-phone.svg"
                      width="24px"
                      alt="mobile phone"
                    />
                    (719) 459-9955
                  </a>
                </li>
                <li>
                  <Link to="/property-listings">
                    <div className="blinking float-none float-xl-left">
                      <i className="fa fa-home" aria-hidden="true" />
                    </div>
                    <span>Find Dream Home</span>
                  </Link>
                </li>
              </ul> */}
              <HeaderNav />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Header
